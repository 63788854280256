<template>
  <div class="lzlinks-camera-container">
    <div class="lzlinks-camera-btn">
      <el-button @click="bindysSystem"  type="primary" plain v-if="$store.state.permissionArr.indexOf('ysSystem:add') != -1"
      >萤石账户绑定</el-button>
             <el-button @click="bindCamera" v-if="$store.state.permissionArr.indexOf('ysDevice:add') != -1"  type="primary" plain
      >添加摄像头</el-button>
    </div>
    <el-table :data="tableData" style="width: 100%" class="lzlinks-table">
      <el-table-column prop="ysName" label="摄像头名称"></el-table-column>
      <el-table-column prop="deviceSerial" label="设备序列号">
      </el-table-column>
      <el-table-column prop="channelNo" label="通道号"></el-table-column>
      <el-table-column prop="validateCode" label="验证码"> </el-table-column>
      <el-table-column prop="createTime" label="绑定时间"></el-table-column>
      <el-table-column prop="modifyTime" label="更新时间"></el-table-column>
      <el-table-column label="操作" width="300">
        <template slot-scope="scope">
          <el-button
            type="danger"
            size="mini"
            plain
            v-if="$store.state.permissionArr.indexOf('ysDevice:delete') != -1"
            @click="del(scope.row.ysId)"
            >删除</el-button
          >
          <el-button
            type="warning"
            size="mini"
            plain
             v-if="$store.state.permissionArr.indexOf('ysDevice:update') != -1"
            @click="edit(scope.row.ysId)"
            >编辑</el-button
          >
          <el-button
            size="mini"
            type="success"
            icon="el-icon-video-camera-solid"
            circle
            @click="monitorClick(scope.row)"
          ></el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      style="float: right"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="table.currentPage"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="table.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    ></el-pagination>
    <el-drawer :visible.sync="drawer" size="30%" @closed="onclose">
      <div class="drawerForm" style="width: 80%; margin-left: 10% ; height:70%; overflow: scroll;">
        <el-form
          ref="form"
          :model="formData"
          label-width="130px"
          :rules="rules"
        >
          <el-form-item label="摄像头名称" prop="ysName">
            <el-input v-model="formData.ysName"></el-input>
          </el-form-item>
          <el-form-item label="设备序列号" prop="deviceSerial">
            <el-input v-model="formData.deviceSerial"></el-input>
          </el-form-item>
          <el-form-item label="通道号" prop="channelNo">
            <el-input v-model="formData.channelNo"></el-input>
          </el-form-item>
          <el-form-item label="验证码" prop="validateCode">
            <el-input v-model="formData.validateCode"></el-input>
          </el-form-item>
          <el-form-item label="触发器选项">
            <el-cascader
              class="lzlinks-cascader"
              :props="props"
              :options="projectsOption"
              @change="cascaderChange"
              v-model="formData.ysaRelList"
            ></el-cascader>
          </el-form-item>
          <el-form-item
            label="触发器已选选项"
            v-if="isEdit && triggerArr.length > 0"
          >
            <ul>
              <li v-for="(item, index) in triggerArr" :key="index">
                <el-button
                  size="mini"
                  type="danger"
                  icon="el-icon-delete"
                  circle
                  plain
                  style="float: right; margin-top: 5px"
                  @click="delOldItem(index)"
                ></el-button>
                {{ item.projectGroupName }}/{{ item.projectName }}/{{ item.alarmName }}
              </li>
            </ul>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submit">提交</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-drawer>
     <el-drawer :visible.sync="ysSystem" size="30%" @closed="onclose">
       <el-form
          ref="form"
          :model="ysform"
          label-width="130px"
          :rules="rules"
        >
       
         <el-form-item label="AppKey" >
            <el-input v-model="ysform.appKey"></el-input>
          </el-form-item> 
         <el-form-item label="Secret" >
            <el-input v-model="ysform.appSecret"></el-input>
          </el-form-item> 
          <el-form-item>
            <el-button type="primary" @click="ysSubmit">提交</el-button>
          </el-form-item>
       </el-form>
     </el-drawer>
  </div>
</template>
<script>
import { visitUrl } from "@/util/global.js";
let _self = {};

export default {
  name: "Camera",
  data() {
    return {
      triggerArr: [],
      ysSystem:false,
      ysform:{
        id:"",
        appKey:"",
        appSecret:"",
        userId:"",
      },
      props: {
        //下拉联动
        lazy: true,
        value: "value",
        label: "label",
        multiple: true,
        lazyLoad(node, resolve) {
        //   const { level } = node;
          if (node.level == 1) {
            _self.getDevicesList(node.data.value);
          } else if (node.level == 2) {
            _self.getRulesList(node.data.value);
          }
          setTimeout(() => {
            let nodes = [];
            if (node.level == 1) {
              nodes = _self.devicesOption;
            } else if (node.level == 2) {
              nodes = _self.rulesOption;
            }
            // 通过调用resolve将子节点数据返回，通知组件数据加载完成
            resolve(nodes);
          }, 1000);
        },
      },
      isEdit: false,
      drawer: false,
      ysName:"",
      formData: {
        deviceSerial: "",
        channelNo: "",
        validateCode: "",
        ysName: "",
        ysaRelList: [],
      },
      ysId: "",
      total: 0,
      tableData: [],
      table: {
        currentPage: 1,
        pageSize: 10,
        fileName: "",
      },
      projectsOption: [],
      devicesOption: [],
      rulesOption: [],
      rules: {
        deviceSerial: [
          { required: true, message: "请输入设备序列号", trigger: "blur" },
        ],
        channelNo: [
          { required: true, message: "请输入通道号", trigger: "blur" },
        ],
        validateCode: [
          { required: true, message: "请选择验证码", trigger: "blur" },
        ],
        deviceId: [
          { required: true, message: "请选择设备名称", trigger: "blur" },
        ],
        projectId: [
          { required: true, message: "请选择项目名称", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    monitorClick: function (obj) {
      window.open(
        visitUrl +
          "#/openCamera?deviceSerial=" +
          obj.deviceSerial +
          "&validateCode=" +
          obj.validateCode +
          "&ysId=" +
          obj.ysId +
          "&channelNo=" +
          obj.channelNo
      );
   
    },
    delOldItem: function (idx) {
      let oldA = _self.triggerArr;

      oldA.splice(idx, 1);
      _self.triggerArr = oldA;
    },
    cascaderChange: function (val) {
      let compareArr = _self.triggerArr;
      val.forEach((el1, idx) => {
        compareArr.forEach((el2) => {
          if (
            el1[0] == el2.projectId &&
            el1[1] == el2.deviceId &&
            el1[2] == el2.ruleId
          ) {
            delete val[idx];
            _self.$message({
              message: "当前触发器重复选择,请删除",
              type: "warning",
            });
          }
        });
      });
      _self.$set(_self.formData, "ysaRelList", val);
    },
    edit: function (ysId) {
      _self.$api.camera
        .getInfoForEdit(ysId)
        .then(function (response) {
          if (response.code == 200) {
            // let ysd = [];

            _self.triggerArr = response.data.ysaRelList;
      
            _self.formData = {
              deviceSerial: response.data.deviceSerial,
              channelNo: response.data.channelNo,
              validateCode: response.data.validateCode,
              ysName: response.data.ysName,
              ysaRelList: [],
            };
            //操作成功显示编辑区
            _self.drawer = true;
            _self.isEdit = true;
            _self.ysId = ysId;
          } else {
            _self.$message({
              message: response.msg,
              type: "warning",
            });
          }
        })
        .catch(function (error) {
          _self.$message({
            message: error.message,
            type: "error",
          });
        });
    },
    del: function (id) {
      _self.$confirm("确认删除？").then(() => {
        _self.$api.camera
          .delInfo(id)
          .then(function (response) {
            if (response.code == 200) {
              _self.$message({
                message: response.msg,
                type: "success",
              });

              _self.getYingshi();
            } else {
              _self.$message({
                message: response.msg,
                type: "warning",
              });
            }
          })
          .catch(function (error) {
            _self.$message({
              message: error.message,
              type: "error",
            });
          });
      });
    },
    onclose: function () {
      _self.$refs.form.resetFields();
    },
    bindCamera: function () {
      _self.formData={
        deviceSerial: "",
        channelNo: "",
        validateCode: "",
        ysName: "",
        ysaRelList: [],
      };
      _self.drawer = true;
      _self.isEdit = false;
    },
    bindysSystem:function(){
      _self.ysSystem=true;
      _self.$api.camera.getYsSystem().then(res=>{
        if (res.data!="") {
          _self.ysform=res.data;
        }
        
      })
    },
    getYingshi: function () {
      _self.$api.camera
        .getYingshiList(_self.table)
        .then(function (response) {
          if (response.code == 200) {
            _self.tableData = response.data.records;
            _self.total = response.data.total;
          } else {
            _self.$message({
              message: response.msg,
              type: "warning",
            });
          }
        })
        .catch(function (error) {
          _self.$message({
            message: error.message,
            type: "error",
          });
        });
    },
    getProjectsList: function () {
      _self.$api.camera
        .getProjects()
        .then(function (response) {
          if (response.code == 200) {
            let newOpts = [];
            response.data.forEach((element) => {
              newOpts.push({
                value: element.projectGroupId,
                label: element.projectGroupName,
              });
            });
            _self.projectsOption = newOpts;
          } else {
            _self.$message({
              message: response.msg,
              type: "warning",
            });
          }
        })
        .catch(function (error) {
          _self.$message({
            message: error.message,
            type: "error",
          });
        });
    },
    getDevicesList: function (projectId) {
      _self.$api.camera
        .getDevices(projectId)
        .then(function (response) {
          if (response.code == 200) {
            let newOpts = [];
            response.data.forEach((element) => {
              newOpts.push({
                value: element.projectId,
                label: element.projectName,
                leaf: false,
              });
            });
            _self.devicesOption = newOpts;
          } else {
            _self.$message({
              message: response.msg,
              type: "warning",
            });
          }
        })
        .catch(function (error) {
          _self.$message({
            message: error.message,
            type: "error",
          });
        });
    },
    getRulesList: function (deviceId) {
      _self.$api.camera
        .getRules(deviceId)
        .then(function (response) {
          if (response.code == 200) {
            let newOpts = [];
            response.data.forEach((element) => {
              newOpts.push({
                value: element.alarmId,
                label: element.alarmName,
                leaf: true,
              });
            });
            _self.rulesOption = newOpts;
          } else {
            _self.$message({
              message: response.msg,
              type: "warning",
            });
          }
        })
        .catch(function (error) {
          _self.$message({
            message: error.message,
            type: "error",
          });
        });
    },
    projectChange: function (pId) {
      _self.getDevicesList(pId);
      _self.$set(_self.formData, "deviceId", "");
    },
    deviceChange: function (dId) {
      _self.getRulesList(dId);
      _self.$set(_self.formData, "ruleId", "");
    },
    handleSizeChange(val) {
      _self.$set(_self.table, "pageSize", val);
      _self.$set(_self.table, "currentPage", 1);
      _self.getList();
    },
    handleCurrentChange(val) {
      _self.$set(_self.table, "currentPage", val);

      _self.getYingshi();
    },
    submit: function () {
      let d = Object.assign({}, _self.formData, { ysId: _self.ysId });
      let arr = [];
      if (d.ysaRelList.length > 0) {
        d.ysaRelList.forEach((element) => {
          arr.push({
            projectGroupId: element[0],
            projectId: element[1],
            alarmId: element[2],
          });
        });
      }

      d.ysaRelList = _self.isEdit ? arr.concat(_self.triggerArr) : arr;

      let _api = _self.isEdit
        ? _self.$api.camera.editInfo(d)
        : _self.$api.camera.submitInfo(d);

      _api
        .then(function (response) {
          if (response.code == 200) {
            _self.$refs.form.resetFields();
            _self.drawer = false;
            _self.getYingshi();
          } else {
            // _self.$message({
            //   message: response.msg,
            //   type: "warning",
            // });
          }
        })
        .catch(function (error) {
          // _self.$message({
          //   message: error.message,
          //   type: "error",
          // });
        });
    },
    ysSubmit(){
      if(!_self.ysform.id){
        _self.$api.camera.addYsSystem(_self.ysform).then(res=>{
        _self.$message({
            message: res.msg,
            type: "success",
          });
          _self.ysSystem=false;
      })
      }else{
        _self.$api.camera.putYsSystem(_self.ysform).then(res=>{
         _self.$message({
            message: res.msg,
            type: "success",
          });
          _self.ysSystem=false;
      })
      }
    },
  },
  beforeCreate: function () {
    _self = this;
  },
  mounted: function () {
    _self.getYingshi();
    _self.getProjectsList();
  },
};
</script>

<style scoped>
.lzlinks-camera-container {

  padding: 0;
  /* padding-top: 20px; */
  width: 100%;
  height: 100%;
  /* background: #f4f4f5; */
  background: #ffffff;
  overflow-y: hidden;
  padding: 30px;
}

.lzlinks-cascader {
  width: 100%;
}
.lzlinks-camera-btn{
  display: flex;
   justify-content: flex-end;
   padding: 0 20px;
}
.drawerForm::-webkit-scrollbar{
     display: none;
      }

</style>